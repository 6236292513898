import contactoImg from "../../imagenes/contacto/contacto.png"
import { useForm, ValidationError } from '@formspree/react';

function Contacto() {

  const [state, handleSubmit] = useForm("mjvdnzyr");
  if (state.succeeded) {
    window.location.href = '/gracias';
  }

  return(
        <div className="grid grid-cols-3 gap-4 mt-10" id="contacto">
            <div className="col-span-3 text-center">
                <h1 className="text-2xl md:text-4xl	inline-block pb-2.5	border-b border-black">Contacto</h1>
            </div>
            
            <div className="col-span-3 md:col-span-1 hidden">
                <div className="container my-9 px-6 mx-auto">
                    <img className="m-auto" src={contactoImg}/>
                </div>
            </div>            

            <div className="col-span-3 md:col-span-3">
              <div className="container my-9 px-6 mx-auto">

                <section className="mb-32 text-center text-gray-800">
                  <div className="max-w-[700px] mx-auto px-3 lg:px-6">

                    {/* <form action="https://formsubmit.co/2e6201bd1b47573f36b2c7d70081ab14" method="POST">
                      
                      <input type="text" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                          
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Nombre" name="name" required/> 
                      
                      
                      <input type="email" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                         
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        placeholder="Correo" name="Correo" required/> 
                      
                      
                      <input type="text" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        placeholder="Asunto" name="Asunto" required/> 
                      
                      
                      <textarea className="
                        form-group mb-14
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                          
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      " id="exampleFormControlTextarea13" name="Mensaje" rows="3" required placeholder="Mensaje"></textarea>
                                           

                      <button type="submit" className="
                        w-full
                        px-6
                        py-2.5
                        bg-[#212236]
                        text-white
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:bg-slate-600 hover:shadow-lg
                        focus:bg-slate-600 focus:shadow-lg focus:outline-none focus:ring-0
                        active:bg-slate-600 active:shadow-lg
                        transition
                        duration-150
                        ease-in-out">ENVIAR MENSAJE</button>

                      <input type="hidden" name="_next" value="/gracias"></input>
                      <input type="hidden" name="_captcha" value="false"></input>

                    </form>*/}

                    <form onSubmit={handleSubmit}>
                      
                      <input type="text" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                          
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Nombre" name="name" required/> 
                      
                      
                      <input type="email" id="email" name="email" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                         
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        placeholder="Correo" required/> 
                      
                      
                      <input type="text" className="form-control block
                        form-group mb-14
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        placeholder="Asunto" name="Asunto" required/> 
                      
                      
                      <textarea className="
                        form-group mb-14
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border-b border-solid border-gray-300                          
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      " id="message" name="message" rows="3" required placeholder="Mensaje"></textarea>
                                           

                      <button type="submit" className="
                        w-full
                        px-6
                        py-2.5
                        bg-[#212236]
                        text-white
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:bg-slate-600 hover:shadow-lg
                        focus:bg-slate-600 focus:shadow-lg focus:outline-none focus:ring-0
                        active:bg-slate-600 active:shadow-lg
                        transition
                        duration-150
                        ease-in-out"
                        disabled={state.submitting}>ENVIAR MENSAJE</button>

                      <input type="hidden" name="_next" value="/gracias"></input>
                      <input type="hidden" name="_captcha" value="false"></input>

                    </form>

                  </div>
                </section>

              </div>
            </div>
           
        </div>        
    )
}

export default Contacto;