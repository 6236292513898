import { useState } from 'react';
import { Transition } from 'react-transition-group';

function Header() {

  const handleClickHeader = (e) => {
    e.preventDefault();
    const id = e.target.getAttribute("id-section");
    const element = document.getElementById(id);
    const headerOffset = 75;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
   
    /* element.scrollIntoView({ behavior: 'smooth'}) */
    if(element){

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

     }  
      
    setIsOpen(false)
  }
  

    const [isOpen, setIsOpen] = useState(false);

    return(
        <header className="z-10	flex items-center justify-center gap-7 px-4 py-3 bg-[#212236] text-white sticky top-0">
            <a id-section="carrusel-home" onClick={handleClickHeader}  className="cursor-pointer text-xs font-medium hidden md:block">HOME</a>
            <a id-section="noticias" onClick={handleClickHeader} className="cursor-pointer text-xs font-medium hidden md:block">MÁS POPULARES</a>
            <a id-section="musica" onClick={handleClickHeader} className="cursor-pointer text-xs font-medium hidden md:block">MUSIC & VIDEO</a>
            <a id-section="carrusel-home" onClick={handleClickHeader} className="text-5xl font-medium hidden md:block cursor-pointer">LAPORTE</a>
            <a id-section="biografia" onClick={handleClickHeader} className="cursor-pointer text-xs font-medium hidden md:block">BIOGRAFIA</a>
            <a id-section="galeria" onClick={handleClickHeader} className="cursor-pointer text-xs font-medium hidden md:block">GALERIA</a>
            <a id-section="contacto" onClick={handleClickHeader} className="cursor-pointer text-xs font-medium hidden md:block">CONTACTO</a>
            <div className="block md:hidden ml-left">
                <a id-section="carrusel-home" onClick={handleClickHeader} className="block px-4 py-2 text-2xl font-bold hover:bg-gray-800">LAPORTE</a>
            </div>
            <div className="block md:hidden ml-auto">
              <button onClick={() => setIsOpen(!isOpen)} className="flex border-0 items-center px-3 py-2 border-0 rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-left" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="4" y1="6" x2="20" y2="6" />
                  <line x1="4" y1="12" x2="14" y2="12" />
                  <line x1="4" y1="18" x2="18" y2="18" />
                </svg>
              </button>
            </div>
            <Transition in={isOpen} timeout={300}>
              {state => (
                <div className={`${isOpen ? 'block' : 'hidden'} md:hidden fixed top-14 right-0 z-50 w-48 py-2 bg-[#23243a] text-white rounded transition-all duration-300 ease-in-out transform`} style={{   transform: state === 'entering' || state === 'entered'     ? 'translateY(0)'     : 'translateY(-100%)' }}>
                  <a id-section="carrusel-home" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">HOME</a>
                  <a id-section="noticias" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">MÁS POPULARES</a>
                  <a id-section="musica" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">MUSIC & VIDEO</a>                  
                  <a id-section="biografia" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">BIOGRAFIA</a>
                  <a id-section="galeria" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">GALERIA</a>
                  <a id-section="contacto" onClick={handleClickHeader} className="block px-4 py-2 text-lg font-medium hover:bg-gray-800">CONTACTO</a>
                </div>
              )}
            </Transition>            
        </header>
    )
}

export default Header;