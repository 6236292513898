import img1 from "../../imagenes/musicavideos/img1.png"
import img2 from "../../imagenes/musicavideos/img2.png"
import img3 from "../../imagenes/musicavideos/img3.png"
import img4 from "../../imagenes/musicavideos/img4.png"
import img5 from "../../imagenes/musicavideos/img5.png"
import img6 from "../../imagenes/musicavideos/img6.png"
import {Spotify} from 'react-spotify-embed';
import { useState } from "react"


function MusicaVideos() {    

    const [selectedTrack, setSelectedTrack] = useState(null);

    const handleTrackClick = (track) => {
        let new_track = "https://open.spotify.com/track/" + track
        setSelectedTrack(new_track);
    };

    const handleCloseClick = () => {
        setSelectedTrack(null);
    };

    return(
        <div className="pb-8 px-4 md:px-0" id="musica">
            <div className="grid md:grid-cols-2 gap-10 mb-7 mt-10">
                <div className="text-center">
                    <h1 className="text-2xl	md:text-4xl	inline-block pb-2.5	border-b border-black">Musica</h1>
                    
                    <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-10">
                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("23bJw4CIqEHtqmntC8AgVj")} src={img1}/></div>
                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("2ywHwJkDgOBpQZV7r9ZKdP")} src={img2}/></div>
                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("1goBvC6TFvvfMulxqPqkle")} src={img3}/></div>

                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("1aN7expPCCPo3L4AAaywMz")} src={img4}/></div>
                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("0aMjQ87hzbRKVBpSqpLye4")} src={img5}/></div>
                        <div><img className="m-auto ease-in-out duration-500 hover:scale-105 cursor-pointer" alt="track miguel laporte" onClick={() => handleTrackClick("0gr91ooNxMx7VRFd1x5OXs")} src={img6}/></div>
                    </div>
                    
                </div>

                <div className="text-center">
                    <h1 className="text-4xl	inline-block pb-2.5	border-b border-black">Videos</h1>                   

                    <div className="my-5">
                        <iframe className="m-auto w-9/12" samesite="none"  height="285" src="https://www.youtube.com/embed/Ie41v5C7EUE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>

                    <div className="my-5">
                        <iframe className="m-auto w-9/12" samesite="none"  height="285" src="https://www.youtube.com/embed/ttWck3AAN9c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>                    

                    <div className="mb-5 hidden">
                        <iframe className="m-auto w-9/12" samesite="none"  height="285" src="https://www.youtube.com/embed/z4zj_KqSj1I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>

                </div>

            </div>

            {selectedTrack && (
              <div className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-75 overflow-hidden">
                <div className="flex items-center justify-center h-screen">
                  <div className="w-full max-w-lg">
                   
                    <Spotify link={selectedTrack}  className="w-full object-cover m-auto w-4/6 md:w-fit" />
                                       
                    <button
                      className="absolute top-2	right-2 p-2 text-white"
                      onClick={handleCloseClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9">
                          <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                        </svg>

                    </button>
                  </div>
                </div>
              </div>
            )}

        </div>
    )
}

export default MusicaVideos;