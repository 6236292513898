import {Spotify} from 'react-spotify-embed';

function MediaPlayer() {
   
    return(
        <>  
            <div id="spotify-embed-iframe">
                <div id="embed-iframe">

                </div>
                <div>
                    <Spotify link="https://open.spotify.com/artist/2BP0jSttglAB5Nr7zL9CUY" />
                </div>
            </div>    
               
        </>
    )    
}

export default MediaPlayer;