import { Link } from "react-router-dom";

function Thanks() {
    return(
        <>
        <div className="bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center">
            <div className="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl">
                <p className="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-48 h-48 text-[#212236]">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </p>
                <p className="text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4 text-[#212236]">Gracias por comunicarte</p>
                <p className="text-gray-500 mt-8 py-2 border-y-2 text-center">
                    ¡Muchas gracias por ponerte en contacto! Queremos hacerte saber que apreciamos<br/> tu mensaje y se le contestara el la brevedad posible. 
                                        
                    <br/><br/>               
                    ¡Gracias por contactarnos y que tengas un excelente día!
                    <br/><br/>

                    <Link to="/">
                        <button className="border text-[#212236] border-[#212236] py-2.5 px-5">
                            Regresar a la pagina
                        </button>
                    </Link>
                </p>

                
            </div>

            
        </div>
        </>
    )
}

export default Thanks;