import img1 from "../../imagenes/galeria/galeria1.png"
import img2 from "../../imagenes/galeria/galeria2.png"
import img3 from "../../imagenes/galeria/galeria3.png"
import img4 from "../../imagenes/galeria/galeria4.png"
import img5 from "../../imagenes/galeria/galeria5.png"
import img6 from "../../imagenes/galeria/galeria6.png"
import img7 from "../../imagenes/galeria/galeria7.png"
import img8 from "../../imagenes/galeria/galeria8.png"
import img9 from "../../imagenes/galeria/galeria9.png"

import React, { useState } from 'react';


function Galeria() {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseClick = () => {
        setSelectedImage(null);
    };

    return(
        <div className="pb-8" id="galeria">
            <div className="text-center mb-5 mt-10">
                <h1 className="text-2xl	md:text-4xl	inline-block pb-2.5	border-b border-black">Galeria</h1>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img1)} src={img1}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img2)} src={img2}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img3)} src={img3}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img7)} src={img7}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img5)} src={img5}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img8)} src={img8}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img4)} src={img4}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img6)} src={img6}/></div>
                <div className="m-4	md:mx-8 ease-in-out duration-300 hover:scale-110 md:block hidden"><img className="w-80 m-auto cursor-pointer" onClick={() => handleImageClick(img9)} src={img9}/></div>                
            </div>

            {/* Render the full screen image */}
            {selectedImage && (
              <div className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-75 overflow-hidden">
                <div className="flex items-center justify-center h-screen">
                  <div className="w-full max-w-lg">
                    <img src={selectedImage} alt={selectedImage} className="w-full object-cover m-auto w-4/6 md:w-fit" />
                    <button
                      className="absolute top-2	right-2 p-2 text-white"
                      onClick={handleCloseClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-9 h-9">
                          <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                        </svg>

                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
    )
}

export default Galeria;
