import Contacto from "../../components/contacto/contacto";
import Galeria from "../../components/galeria/galeria";
import Biografia from "../../components/biografia/biografia";
import MusicaVideos from "../../components/musica-videos/musica-videos";
import Noticias from "../../components/noticias/noticias";
import Carrusel from "../../components/carrusel/carrusel";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";


function Home() {
    return(
        <div>
            <Header/>
            <Carrusel/>
            <Noticias/>
            <MusicaVideos/>
            <Biografia/>
            <Galeria/>
            <Contacto/>
            <Footer/>                        
            
        </div>       
    )
}

export default Home;