import MediaPlayer from "../reproductor-spotify/reproductor";

function Noticias() {
    return(
        <div className="" id="noticias">
            <div className="text-center mb-5 mt-10 hidden">
                <h1 className="text-2xl	md:text-4xl	inline-block pb-2.5	border-b border-black">NOTICIAS</h1>
            </div>
            <div className="grid grid-cols-1">                
                <div className="pt-5 md:pb-20">
                    <div className="m-auto text-black min-h-full items-center justify-center flex px-8 py-8 md:px-16 md:py-0">
                      < MediaPlayer/>  
                    </div> 
                </div>
                               
            </div>
        </div>
    )
}

export default Noticias;