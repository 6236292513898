function Carrusel() {
    return(
        <div id="carrusel-home">            
            <div className="bg-black">
                {/* DSC01763 DSC01980 <img className="m-auto" src="https://static1.diariosur.es/www/multimedia/201811/09/media/cortadas/laporte2-kIz-U601517837600muC-984x608@Diario%20Sur.jpg" /> */}
                <img src="https://res.cloudinary.com/dswzcvwem/image/upload/v1715034212/miguel%20laporte/DSC01979_3.f9341ef3483b48fbb092_k3tq26.webp" />
            </div>                          
        </div>
         
    )
}

export default Carrusel;