import img2 from "../../imagenes/biografia/img2.jpeg"

function Biografia() {
    return(
        <div className="pb-8 pt-5">
             <div className="text-center mb-7 mt-10" id="biografia">
                <h1 className="text-2xl	md:text-4xl	inline-block pb-2.5	border-b border-black">Biografia</h1>
            </div>

            <div className="grid  md:grid-cols-2 gap-10">
                <div className="">
                    <img className="w-full m-auto" src={img2}/>
                </div>
                
                <div className="px-14 m-auto">
                    <h2 className="hidden text-center text-2xl md:text-3xl">¿ Quién soy ?</h2>
                    <br/>
                    <p className="text-center text-lg">Laporte es un  artista malagueño con más de 20 años de trayectoria artística. </p>
                                        
                    <p className="text-center text-lg">Comenzó a los 10 años en el coro de niños de su ciudad en Málaga  y desde entonces no ha parado de formarse y trabajar para dar su nombre a conocer en el ámbito musical. </p>
                    <br/>
                    <p className="text-center text-lg">Los últimos 8 años, ha estado en Sudamérica (México y Perú) dando a conocer su música como cantante y compositor tanto en televisión como en teatros, salas de conciertos y compartiendo escenario con artistas reconocidos  por toda Sudamérica . </p>
                    <br/>
                    <p className="text-center text-lg">Esta firmado como artista en la discografíca Kiva music del reconocido productor Mexicano Stefano Vieni y como compositor por una de las editoriales mas importante  como es Warner Chappell México.</p>
                    <br/>
                    <p className="text-center text-lg"> Actualmente Laporte está preparando su nuevo trabajo discográfico que sin duda dará mucho que hablar.</p>
                    <br/>
                </div>
                
                <div className="px-14 hidden">
                    <p className="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus hendrerit fermentum enim, id laoreet eros. Nam pretium erat ante, nec molestie velit eleifend in. Duis semper, odio et dapibus faucibus, eros massa pharetra odio, bibendum congue mauris odio sed magna. Cras at eros sed urna fermentum cursus quis a nulla. Donec non lacus dignissim, consectetur mauris ac, mollis felis. Sed dolor erat, blandit quis lectus quis, tincidunt vestibulum leo. Proin ultricies, lacus vel sagittis faucibus, ligula lectus porta metus, nec pretium elit sem eu justo. Quisque vel elit et eros congue porttitor id a purus. Integer luctus est quis urna vulputate, faucibus vestibulum leo ultrices. Mauris lobortis bibendum nisl, eu varius turpis eleifend ut. Nam eleifend bibendum purus.</p>
                </div>
                
                <div className=" hidden">
                    <img className="w-9/12 m-auto" src={img2}/>
                </div>
            </div>

        </div>
    )
}

export default Biografia;