import { Route,Routes,Link } from "react-router-dom";
import Header from "./components/header/header"
import Footer from "./components/footer/footer"
import Home from "./container/page/home";
import Error404 from "./container/error/error"
import Thanks from "./container/page/thanks";

function App() {
  return (
    <div className="scroll-smooth font-['Arimo']">
     

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gracias" element={<Thanks/>} />    
        <Route path="*" element={<Error404/>} />            
      </Routes>

      
    </div>
  );
}

export default App;
